<template>
    <v-dialog value="true" scrollable persistent fullscreen>
        <v-card>
            <v-card-title class="primary white--text py-sm-2">
                <span class="text-h5">Política de Privacidade</span>
                <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text class="elevation-2">
                <v-container grid-list-md text-xs-center>
                    <REV01 />
                </v-container>
            </v-card-text>

            <v-card-actions class="justify-space-around">
                <router-link :to="{ path: '/' }" tag="v-btn">
                    <v-btn color="primary"> Fechar </v-btn>
                </router-link>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import REV01 from "@/components/shared/Privacy-rev01";

export default {
    components: {
        REV01,
    },
};
</script>